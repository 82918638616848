body {
  background-color: rgb(23, 29, 39);
  color: rgba(255, 255, 255, 0.95);
  font-family: 'Georgia';
  font-size: 16px;
}

a {
  color: skyblue;
}

h2 {
  margin-top: 80px;
}

h3 {
  margin-top: 42px;
}

p {
  line-height: 1.3;
}

.glow {
  -webkit-animation: glow 2s infinite;
}

@keyframes glow {
  0% {color:rgba(255, 255, 255, 0.95);}
  50% {color:skyblue;}
  100%{color:rgba(255, 255, 255, 0.95);}
}

@media only screen and (min-width: 900px) {
  body {
    font-size: 18px;
  }
}
